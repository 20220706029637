.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.protocol-tokens-container {
  max-width: 1200px;
  min-height: calc(100vh - 120px);
  margin: 0 auto;
  padding: 2rem;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 2rem;
}

.tokens-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.token-card {
  background: #0e1020;
  font-size: 1.2rem;
  border-radius: 4px;
  padding: 2rem;
  position: relative;
  backdrop-filter: blur(10px);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.token-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.token-card.coming-soon {
  filter: blur(3px);
  opacity: 0.7;
  pointer-events: none;
  user-select: none;
}

.token-card.coming-soon:hover {
  transform: none;
  box-shadow: none;
}

.token-card.coming-soon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  pointer-events: none;
}

.token-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.token-icon {
  width: 40px;
  height: 40px;
}

.token-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}

.token-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  min-height: 80px;
  font-size: 1.2rem;
}

.token-stats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.stat-item {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
}

.token-buttons {
  display: flex;
  gap: 1rem;
  margin-top: auto;
}

.token-button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  background: #ff7028;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.token-button.secondary {
  background: rgba(49, 54, 85, 0.5);
}

.token-button:hover:not(.disabled) {
  background: #ff8442;
  transform: translateY(-1px);
}

.token-button.secondary:hover:not(.disabled) {
  background: rgba(49, 54, 85, 0.7);
}

.token-button.disabled {
  background: rgba(49, 54, 85, 0.5);
  cursor: not-allowed;
  opacity: 0.7;
}

.coming-soon-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #ff7028;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
}

/* Reward Programs Section */
.reward-programs-section {
  margin-top: 2rem;
  background: rgba(13, 14, 22, 0.6);
  border-radius: 0.75rem;
  padding: 2rem;
  backdrop-filter: blur(10px);
}

.section-title {
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 2rem;
}

.reward-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.reward-tab {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1.2rem;
  background: rgba(49, 54, 85, 0.3);
  color: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  /* transition: all 0.2s ease; */
}

.reward-tab:hover {
  background: rgba(49, 54, 85, 0.4);
}

.reward-tab.active {
  background: #ff7028;
  color: white;
  border-color: #ff7028;
}

.reward-overview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
  background: rgba(49, 54, 85, 0.3);
  border-radius: 0.75rem;
  padding: 2rem;
}

.overview-card {
  background: transparent;
  border: none;
  padding: 0;
}

.overview-card h3 {
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.overview-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
}

.overview-card li {
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;
}

.overview-card li:before {
  content: "•";
  color: #ff7028;
  position: absolute;
  left: 0;
}

.reward-programs-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.reward-program {
  background: rgba(49, 54, 85, 0.3);
  border-radius: 0.75rem;
  padding: 2rem;
  color: #fff;
}

.reward-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.reward-description {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 2rem;
  font-size: 0.95rem;
  line-height: 1.5;
}

.reward-table {
  background: rgba(49, 54, 85, 0.3);
  border: 1px solid rgba(49, 54, 85, 0.5);
  border-radius: 0.75rem;
  overflow: hidden;
}

.reward-table-header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 1rem;
  background: rgba(49, 54, 85, 0.5);
  color: #fff;
  font-weight: 500;
}

.reward-table-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0.75rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-top: 1px solid rgba(49, 54, 85, 0.5);
}

.reward-table-row:nth-child(2) {
  background: rgba(255, 112, 40, 0.15);
  color: #fff;
  font-weight: 500;
}

.reward-table-row:not(:first-of-type):hover {
  background: rgba(49, 54, 85, 0.4);
}

.reward-table-row.three-col {
  grid-template-columns: 1fr 2fr 1fr;
}

.reward-info {
  padding: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6;
}

@media (max-width: 1200px) {
  .reward-programs-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .protocol-tokens-container {
    padding: 1rem;
  }

  .reward-overview {
    grid-template-columns: 1fr;
    padding: 1.5rem;
  }

  .reward-programs-grid {
    grid-template-columns: 1fr;
  }

  .reward-program {
    padding: 1.5rem;
  }

  .reward-table-header,
  .reward-table-row {
    font-size: 0.9rem;
  }
}

.rewards-draft-banner {
  background: rgba(255, 112, 40, 0.15) !important;
  border: 1px solid rgba(255, 112, 40, 0.3) !important;
  margin-bottom: 2rem !important;
  border-radius: 0.75rem !important;
  padding: 1.5rem !important;
}

.rewards-draft-banner .custom-banner-content {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  text-align: center;
}

.overview-cta-button {
  width: 100%;
  padding: 1rem;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  background: #ff7028;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.overview-cta-button:hover {
  background: #ff8442;
  transform: translateY(-1px);
}

.overview-cta-button.secondary {
  background: rgba(49, 54, 85, 0.5);
}

.overview-cta-button.secondary:hover {
  background: rgba(49, 54, 85, 0.7);
}

.main-cta-banner {
  background: linear-gradient(45deg, rgba(255, 112, 40, 0.15), rgba(255, 112, 40, 0.05));
  border: 1px solid rgba(255, 112, 40, 0.3);
  border-radius: 0.75rem;
  padding: 2rem;
  margin: 3rem 0;
  text-align: center;
}

.main-cta-content h2 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1rem;
}

.main-cta-content p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 2rem;
}

.main-cta-button {
  padding: 1rem 3rem;
  font-size: 1.2rem;
  font-weight: 500;
  background: #ff7028;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.main-cta-button:hover {
  background: #ff8442;
  transform: translateY(-1px);
}

.reward-program-actions {
  margin-top: 1.5rem;
  text-align: center;
}

.reward-action-button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  background: rgba(255, 112, 40, 0.15);
  color: #ff7028;
  border: 1px solid #ff7028;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.reward-action-button:hover {
  background: rgba(255, 112, 40, 0.25);
  transform: translateY(-1px);
}

.floating-cta {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 100;
}

.floating-cta-button {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  background: #ff7028;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
}

.floating-cta-button:hover {
  background: #ff8442;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .floating-cta {
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
  }

  .floating-cta-button {
    width: 100%;
  }

  .main-cta-content h2 {
    font-size: 1.5rem;
  }

  .main-cta-content p {
    font-size: 1rem;
  }

  .main-cta-button {
    width: 100%;
    padding: 1rem;
  }
}

.incentives-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.incentive-card {
  background: rgba(49, 54, 85, 0.3);
  border-radius: 0.75rem;
  padding: 2rem;
  color: #fff;
}

.incentive-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.incentive-card p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.5;
}

.incentive-details ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.incentive-details li {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;
  font-size: 1.1rem;
}

.incentive-details li:before {
  content: "•";
  color: #ff7028;
  position: absolute;
  left: 0;
}

@media (max-width: 768px) {
  .incentives-grid {
    grid-template-columns: 1fr;
  }

  .incentive-card {
    padding: 1.5rem;
  }

  .incentive-card h3 {
    font-size: 1.3rem;
  }

  .incentive-card p {
    font-size: 1rem;
  }

  .incentive-details li {
    font-size: 1rem;
  }
}
