.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-sm);
  border-radius: 3px;
  overflow: hidden;
  color: #ffffff;
  background: #1a1c2c;
  /* background: linear-gradient(90deg, rgba(30, 34, 61, 0.9) 0%, rgba(38, 43, 71, 0.9) 100%); */
  /* box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01); */
}

#light .Tab.block {
  background: #eeeef0;
  color: #000000;
}

.Tab-option-icon {
  margin-right: 0.465rem;
  opacity: 0.7;
  filter: invert(1);
}

#light .Tab-option-icon {
  filter: invert(0);
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
}

/* .Tab.block .Tab-option:hover {
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(90deg, rgba(35, 57, 172, 1) 0%, rgba(43, 59, 147, 1) 100%);
} */

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: #292b3f;
  color: #ffffff;
}

#light .Tab-option.active {
  background: #e1e2e5;
  color: #000000;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline {
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.Tab.inline::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-base);
}

#light .Tab.inline .Tab-option {
  background: none;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}
