.Approve-tokens-input-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 430px;
  height: 100px;
  flex-shrink: 0;
  padding: 10px 2rem;
  margin-bottom: 10px;
  background: #1e1e1e;
  border-radius: 8px;
}

.Approve-tokens-input-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 0.31rem;
}

.Approve-tokens-input-section-bottom {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 0.31rem;
}

.Approve-token-input-max {
  position: absolute;
  right: 1rem;
  transform: translateY(-50%);
  align-items: center;
  background: #2e2e2e;
  margin-right: 180px;
  margin-top: 25px;
}

.Approve-token-input-max:hover {
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(90deg, rgba(58, 78, 252, 1) 0%, rgba(51, 68, 222, 1) 100%);
}

.token-image {
  width: 25px;
  height: 25px;
  margin-right: 1rem;
}

.muted {
  font-size: 14px;
  margin-left: 2px;
  opacity: 0.7;
}

input.Approve-swap-input {
  width: 242.819px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  background: #181717;
  padding: 5px;
  margin-right: 10px;
}
