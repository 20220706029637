.custom-banner {
  background: rgba(62, 180, 137, 0.1);
  border-radius: 8px;
  padding: 16px 24px;
  margin-bottom: 24px;
  border: 1px solid rgba(62, 180, 137, 0.2);
  box-shadow: 0 2px 8px rgba(62, 180, 137, 0.05);
  position: relative;
}

.custom-banner-content {
  text-align: center;
  color: #eee;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.2px;
  padding-right: 20px;
}

.custom-banner a {
  color: #3eb489 !important;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.2s ease;
}

.custom-banner a:hover {
  text-decoration: underline;
  color: #4cc89a;
}

.custom-banner-dismiss {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #eee;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 8px;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.custom-banner-dismiss:hover {
  opacity: 1;
}

/* Special styles for the morph launch banner */
.morph-launch-banner {
  position: relative !important;
  z-index: 10;
  margin: 0;
  border-radius: 0;
  background: rgba(255, 112, 40, 0.25);
  backdrop-filter: blur(10px);
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.063);
  color: #eee;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.morph-launch-banner .custom-banner-content {
  font-size: 1.5rem;
  font-weight: 500;
  padding-right: 0;
}

/* Override default banner styles for morph launch banner */
.morph-launch-banner.custom-banner {
  background: rgba(255, 112, 40, 0.25);
  border: none;
  box-shadow: none;
}

.morph-launch-banner.custom-banner a {
  color: #ff7028 !important;
}

.morph-launch-banner.custom-banner a:hover {
  color: #ff8f55 !important;
}
