.Modal {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.Modal-scrollable .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 5.5rem);
  overflow-y: auto;
  padding-right: 5px;
}

.Modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
}

.Wallet-Connect-Modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d0f19;
}

#light .Wallet-Connect-Modal-backdrop {
  background: #ffffff;
}

.Modal-content {
  z-index: 20;
  position: relative;
  min-width: 30%;
  max-width: 30%;
  max-height: 90vh;
  overflow: auto;
  background: #0d0f19;
  border-radius: 14px;
  color: #ffffff;
}

@media screen and (max-width: 600px) {
  .Modal-content {
    min-width: calc(100% - 40px);
  }
}

.Modal-content-light {
  z-index: 20;
  position: relative;
  min-width: 498.372px;
  max-width: 100%;
  max-height: 90vh;
  overflow: auto;
  background: #ffffff;
  border-radius: 14px;
}

/* #light .Modal-content{
  background: #ffffff;
} */

#light .Modal-content {
  background: #ffffff;
  color: #000000;
}

#light .divider {
  border-bottom: 1px solid #000000;
}

.divider {
  border-bottom: 1px solid #ffffff29;
}

.Modal.non-scrollable .Modal-content {
  overflow: visible;
}

.Modal-header-wrapper {
  margin: 1.5rem;
}

.Modal-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Modal-body {
  max-height: calc(80vh - 8.5rem);
  overflow-y: auto;
  /* for scrollbar to have a space on left and right */
  padding-right: 0.5rem;
  margin: 1.5rem 0.8rem 1.5rem 1.5rem;
}

.Modal-body::-webkit-scrollbar {
  width: 0.6rem;
}

.Modal-body::-webkit-scrollbar-track {
  background-color: #1c1c1c;
  border-radius: 155rem;
}

.Modal-body::-webkit-scrollbar-thumb {
  background-color: #949393;
  border-radius: 155rem;
}
/*
border : 2px solid #9CA3AF;
border-radius: 10px; */

.Modal-title {
  text-align: left;
  font-size: 26px; /* var(--font-md); */
  font-family: tektur;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;
}

#light .email-input-container > input {
  border: 2px solid #9ca3af;
  border-radius: 10px;
}

#light .Modal-title {
  color: #000000;
}

.Modal-close-button {
  text-align: right;
}

.Modal-close-icon {
  opacity: 0.6;
  cursor: pointer;
  text-align: right;
  display: inline-block;
}

.Modal-close-icon:hover {
  opacity: 0.9;
}

.Modal-note {
  margin-bottom: 1.5rem;
  margin-top: 0.8rem;
}
