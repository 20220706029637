.Checkbox {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.Checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
}

.Checkbox .Checkbox-icon {
  font-size: 1.25rem;
  color: #ff7028;
  margin-right: 0.62rem;
  margin-bottom: 0;
}

/* #light .Checkbox-icon{
  color : #ff7028; 
} */

.Checkbox.disabled .Checkbox-icon {
  color: #47509e;
}

.Checkbox-icon.inactive {
  color: rgba(255, 255, 255, 0.7);
}

#light .Checkbox-icon.inactive {
  color: #d9d9d9;
}

.Checkbox-label {
  display: inline-block;
  vertical-align: middle;
  font-size: var(--font-sm);
}

#light .Checkbox-label {
  color: #000000;
}
