.button {
  cursor: pointer;
  font-size: var(--font-sm);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: normal;
  padding: 1.1rem 1.6rem;
  border: none;

  &.primary {
    background: #ff7028;
    color: #fff;
    border-radius: 0.3rem;
    padding: 1.65rem 1.6rem;
    line-height: 1;

    &:not([disabled]):hover {
      background: #ff7028;
    }

    &:not([disabled]):active {
      background: #ff7028;
    }
  }

  &.approve-done {
    display: flex;
    width: 365px;
    height: 47px;
    padding: 15.5px 0px 17.5px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: #13f287;
    margin-top: 10px;

    &:not([disabled]):hover {
      opacity: 0.8;
    }

    &:not([disabled]):active {
      opacity: 0.8;
    }
  }

  &.approved {
    display: flex;
    flex-direction: row;
    width: 109px;
    height: 34px;
    padding: 7.5px 6.434px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: rgba(19, 242, 135, 0.1);
    padding-left: 10px;

    &:not([disabled]):hover {
      background: #68aa49;
    }

    &:not([disabled]):active {
      background-color: #59923f;
    }
  }

  &.await {
    display: flex;
    width: 109px;
    height: 34px;
    padding: 7.5px 22px;
    justify-content: center;
    background: #1e1e1e;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #13f287;
    color: #13f287;
  }

  &.await:hover {
    background: rgba(19, 242, 135, 0.1);
  }

  #light &.primary-action {
    background: #ff7028;
  }

  &.primary-action {
    background: #ff7028;
    color: #fff;
    border-radius: 0.3rem;
    padding: 1.65rem 1.6rem;
    line-height: 1;

    &:not([disabled]):hover {
      background: #ff7028;
      box-shadow: 0 0 0.4rem 0.5rem rgb(45 66 252 / 20%);
    }

    &:not([disabled]):active {
      background: #ff7028;
      box-shadow: 0 0 0.4rem 0.6rem rgb(45 66 252 / 20%);
    }
  }

  #light &.semi-clear {
    background: #ff7028;
    color: #ffffff;
  }

  &.semi-clear {
    background: #ff7028;
    color: #fff;
    border-radius: var(--border-radius-sm);
    line-height: 1;
    letter-spacing: 0;

    &:not([disabled]):hover {
      background: #ff7028;
    }

    &:not([disabled]):active {
      background: #ff7028;
    }
  }

  &.clear {
    background-color: transparent;
    border: 1px solid var(--dark-blue-border);
    border-radius: var(--border-radius-sm);
    color: #fff;
    padding: 0.75rem 1.25rem;
    font-weight: normal;
    justify-content: left;

    > .btn-image {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: 2.3rem;
      max-width: 2.3rem;
      margin-right: 1.15rem;
    }

    &:not([disabled]):hover {
      background: var(--dark-blue-hover);
    }

    &:not([disabled]):active {
      background: var(--dark-blue-active);
    }
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.9;
  }
}
